import axios from 'axios'
import Vue from 'vue'

export const baseURL = 'http://server.yenestock.com:1077/api/'
// export const baseURL = 'http://localhost:1077/api/'

const axiosIns = axios.create({
  baseURL,
  headers: {
    isWeb: true,
  },
  withCredentials: true,
  timeout: 400000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
