// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'
import '@axios'
import VueCompositionAPI from '@vue/composition-api'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import Vue from 'vue'
import App from './App.vue'
// Global Components
import './global-components'
import router from './router'
import store from './store'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

require('@core/assets/fonts/yenestock/yenestock_font.css')
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
