/* eslint-disable no-self-assign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import { itemsTypeEnum } from '@/api/field_model'
import { Ajax, isApiSuccess, RouteConst } from '@/libs/ajax'

const state = {
  stockItems: [],
  shopItems: [],
}

const mutations = {
  addSingleItem(state, { itemsType, itemModel }) {
    if (itemsType === itemsTypeEnum.stock) {
      state.stockItems.splice(0, 0, itemModel)
    } else if (itemsType === itemsTypeEnum.shop) {
      state.shopItems.push(itemModel)
    }
  },
  updateSingleItem(state, { itemsType, itemModel }) {
    console.debug(itemsType, itemModel)
    const itemsCopy = [...(itemsType === itemsTypeEnum.stock
      ? state.stockItems
      : state.shopItems)]

    const itemIndex = itemsCopy.findIndex(element => element.id === itemModel.id)
    console.debug('updateSingleItem', { itemIndex })

    if (itemIndex !== -1) {
      itemsCopy[itemIndex] = itemModel

      if (itemsType === itemsTypeEnum.stock) {
        state.stockItems = itemsCopy
      } else {
        state.shopItems = itemsCopy
      }
    }
  },
  addBulkItems(state, { itemsType, items }) {
    if (itemsType === itemsTypeEnum.stock) {
      state.stockItems = items
    } else if (itemsType === itemsTypeEnum.shop) {
      state.shopItems = items
    }
  },
  deleteSingleItem(state, { itemsType, itemModel }) {
    if (itemsType === itemsTypeEnum.stock) {
      const index = state.stockItems.indexOf(itemModel)
      state.stockItems.splice(index, 1)
    } else if (itemsType === itemsTypeEnum.shop) {
      const index = state.shopItems.indexOf(itemModel)
      state.shopItems.splice(index, 1)
    }
  },
}

const actions = {
  async  addItem({ commit }, itemModel) {
    const res = await new Ajax(RouteConst.addItemToDBUrl, { body: { itemModel } })
    if (isApiSuccess(res)) {
      commit('ADD_ITEM', itemModel)
    }
  },
  async  deleteItem({ commit }, itemModel) {
    const res = await new Ajax(RouteConst.deleteItemUrl, { body: { id: itemModel.id } })
    if (isApiSuccess(res)) {
      commit('DELETE_ITEM', itemModel)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
