import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Vue from 'vue'
import VueExcelEditor from 'vue-excel-editor'
// eslint-disable-next-line import/extensions
import NProgressContainer from 'vue-nprogress/src/NprogressContainer'

Vue.use(VueExcelEditor)
Vue.component('nprogress-container', NProgressContainer)

Vue.component(FeatherIcon.name, FeatherIcon)
