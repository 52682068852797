// Modules
import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import appConfig from './app-config'
import moduleAuth from './auth/moduleAuth'
import moduleCollections from './collections/moduleCollections'
import moduleItemHistory from './itemHistory/moduleItemHistory'
import moduleGetItemsStock from './items/moduleGetItemsStock'
import moduleItems from './items/moduleItems'
import moduleSearchItems from './searchItems/moduleSearchItems'
import verticalMenu from './vertical-menu'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: moduleAuth,
    items: moduleItems,
    collections: moduleCollections,
    searchItems: moduleSearchItems,
    getItemsStock: moduleGetItemsStock,
    itemHistory: moduleItemHistory,
  },
  plugins: [
    createPersistedState({
      paths: ['auth.AppActiveUser', 'auth.companyInfo'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  strict: process.env.DEV,
})
