const mySqlDbTables = {
  companies: 'companies',
  user: 'user',
  feedbacks: 'feedbacks',
  supplier: 'supplier',
  dailyTips: 'dailyTips',
  logs: 'logs',
  billoflading: 'billoflading',
  branch: 'branch',
  expense: 'expense',
  itemHistories: 'itemHistories',
  storeItems: 'storeItems',
  items: 'items',
  purchase: 'purchase', // sale
  saleItem: 'saleItem',
  groupcollection: 'groupcollection',
  mark: 'mark',
  brand: 'brand',
  measurement: 'measurement',
  history: 'history',
  customer: 'customer',
  mycredits: 'mycredits',
  itemHistories2: 'itemHistories2',
}

const mysqlCommonModel = {
  id: 'id',
  compId: 'compId',
  createdAt: 'createdAt',
  userId: 'userId',
  adminId: 'adminId',
  purchaseId: 'purchaseId',
  itemId: 'itemId',
  branchId: 'branchId',
  supplierId: 'supplierId',
  updatedAt: 'updatedAt',
  isDeleted: 'isDeleted',
}

const billOfLadingModelField = {
  cadNo: 'cadNo',
  blNo: 'blNo',
  mscNo: 'mscNo',
  nm: 'nm',
  shippedOnBoard: 'shippedOnBoard',
}

const branchModelField = {
  name: 'name',
  address: 'address',
  status: 'status',
  type: 'type',
  // isDeleted: 'isDeleted',
}

const branchTypeField = {
  store: '0',
  stock: '1',
}

const collectionModelField = {
  name: 'name',
}

const companyModelField = {
  name: 'name',
  logo: 'logo',
  separateAddItem: 'sAI',
  restrictSellOutOfStock: 'rSOOS',
  allowCustomSalePrice: 'aCSP',
  twoFactorVerification: 'tFV',
  address: 'address',
  companyDomain: 'companyDomain',
  companyPassword: 'companyPassword',
  minStockQty: 'minStockQty',
  expireDayRemindDay: 'expireDayRemindDay',
  email: 'email',
  lastSyncDate: 'lastSyncDate',
  hasSetupComplete: 'hasSetupComplete',
  trialExpireDay: 'trialExpireDay',
  enableOfflineMode: 'eof',
  minDayToForceUpdate: 'mDTFU',
  isNotPaid: 'iNP',
  taxField: 'taxF',
  taxPercentage: 'taxP',
  costMethod: 'costM',
  multipleBranchMode: 'mBM',
  notifyUnitPriceChange: 'nUPC',
  notifyUserLogin: 'nUL',
  notifyOnSale: 'nOS',
}

const customerModelField = {
  name: 'name',
  address: 'address',
  company: 'company',
  phone: 'phone',
}

const expenseModelField = {
  date: 'date',
  payee: 'payee',
  note: 'note',
  amount: 'amount',
}

const historyModelField = {
  result: 'result',
  type: 'type',
  item: 'item',
  note: 'note',
  // userId: 'userId',
  supplierId: 'supplierId',
  userName: 'userName',
  userRole: 'userRole',
  originalId: 'originalId',
  // branchId: 'branchId',
}

const historyTypeField = {
  stockOut: '0',
  storeOut: '1',
  transferFromBranchToBranch: '2',
  creditCancellation: '3',
  deleteStoreItem: '4',
  deleteStockItem: '5',
  editStockQty: '6',
  editStoreQty: '7',
  transferFromStockToStore: '8',
  transferFromStoreToStock: '9',
  stockIn: '10',
  returnItemFromSale: '11',
  initOpeningStock: '12',
  editPurchasePrice: '13',
}

const itemHistoriesModelField = {
  purchasePrice: 'pP',
  unitPrice: 'uP',
  originalId: 'oId',
  qty: 'qty',
  qtyToBeDeducted: 'qtyToBeDeducted',
  expireDate: 'exD',
  itemId: 'itemId',
  historyId: 'historyId',
  note: 'note',
}

const itemModelField = {
  originalId: 'oId',
  image: 'image',
  name: 'name',
  code: 'code',
  invoiceNo: 'invoiceNo',
  invoiceDate: 'invoiceDate',
  partNo: 'partNo',
  barcode: 'barcode',
  unitPrice: 'unitPrice',
  minPrice: 'minPrice',
  maxPrice: 'maxPrice',
  qty: 'qty',
  location: 'location',
  groupp: 'groupp',
  mark: 'mark',
  description: 'description',
  branchId: 'branchId',
  branchName: 'branchName',
  branchType: 'branchType',
  manufacturer: 'manufacturer',
  manufacturerDate: 'manufacturerDate',
  brand: 'brand',
  measurement: 'measurement',
  status: 'status',
  stockItemId: 'stockItemId',
  stockOriginalItemId: 'sOIId',
  purchasePrice: 'purchasePrice',
  expireDate: 'expireDate',
  newExpireDate: 'new_expireDate',
  expireRemindId: 'expireRemindId',
  lowStock: 'lowStock',
  groupId: 'groupId',
  measurementId: 'measurementId',
  markId: 'markId',
  brandId: 'brandId',
}

const myCreditModelField = {
  items: 'items',
  companyIdentifer: 'companyIdentifer',
  creditStatus: 'creditStatus',
  creditPaymentAmountHistory: 'creditPaymentAmountHistory',
}

const saleModelField = {
  items: 'items',
  customerId: 'customerId',
  customerPhoneNo: 'customerPhoneNo',
  type: 'type',
  customerName: 'customerName',
  returnDate: 'returnDate',
  creditStatus: 'creditStatus',
  creditPaymentAmountHistory: 'creditPaymentAmountHistory',
  totalAmount: 'totalAmount',
  purchasePrice: 'purchasePrice',
  storeTotal: 'storeTotal',
  stockTotal: 'stockTotal',
  branchName: 'branchName',
  branchType: 'branchType',
  adminName: 'adminName',
  adminRole: 'adminRole',
  unpaidAmount: 'unpaidAmount',
  chequeNo: 'chequeNo',
  taxPercentage: 'taxP',
  taxLabel: 'taxL',
  includeVat: 'icV',
  isOffline: 'isOffline',
  note: 'note',
}

const saleItemModelField = {
  purchaseId: 'purchaseId',
  qty: 'qty',
  amount: 'amount',
  itemId: 'itemId',
  storeId: 'storeId',
  branchType: 'branchType',
  customItemIdentifer: 'customItemIdentifer',
  saleHistories: 'saleHistories',
  average: 'average',
}

const saleTypeField = {
  cash: '0',
  credit: '1',
  cheque: '2',
}

const creditStatusField = {
  unPayed: '0',
  advance: '1',
  payed: '2',
}

const userModelField = {
  username: 'username',
  avatar: 'avatar',
  role: 'role',
  phone: 'phone',
  password: 'password',
  allowed: 'allowed',
  lastLogin: 'lastLogin',
  version: 'version',
  token: 'token',
  salesBranchId: 'salesBranchId',
  multipleSalesBranchId: 'mSBI',
}

const userTypeRole = {
  admin: '0',
  sales: '1',
  fake: '2',
  stockKeeper: '3',
}

const logsType = {
  phoneRegister: '1',
  wrongCompany: '2',
}

const logsModelField = {
  phone: 'phone',
  companyName: 'companyName',
  type: 'type',
}

const itemStatus = {
  available: '0',
  unAvailable: '1',
}

const chooseReportDateType = {
  today: 'ChooseReportDateType.today',
  yesterday: 'ChooseReportDateType.yesterday',
  thisMonth: 'ChooseReportDateType.thisMonth',
  thisWeek: 'ChooseReportDateType.thisWeek',
  thisYear: 'ChooseReportDateType.thisYear',
  custom: 'ChooseReportDateType.custom',
}

const costMethodField = {
  fifo: '1',
  lifo: '2',
  average: '3',
}

const buildUserRoleText = value => {
  switch (value) {
    case userTypeRole.admin:
      return 'Admin'
    case userTypeRole.sales:
      return 'Sales'
    case userTypeRole.stockKeeper:
      return 'Stock keeper'
    default:
      return ''
  }
}

const errorMessageKey = {
  noCompanyCode: '2201',
  incorrectPassword: '2202',
  notEnoughUnit: '2203',
  anAuthorizedCode: '2204',
  noSetupForTwoFactorAuthCode: '2205',
  paymentsLoggedThatAreAfterNewEndDate: '2206',
  passwordAlreadyUsedForAnotherUser: '2207', // The Password is used for another account please enter another password
}

const itemsTypeEnum = { stock: 'stock', shop: 'shop', search: 'search' }

const TaxFilter = {
  all: 1,
  includeTax: 2,
  excludeTax: 3,
}

export {
  TaxFilter, billOfLadingModelField,
  branchModelField,
  branchTypeField, buildUserRoleText, chooseReportDateType, collectionModelField,
  companyModelField, costMethodField, creditStatusField, customerModelField, errorMessageKey, expenseModelField,
  historyModelField,
  historyTypeField,
  itemHistoriesModelField,
  itemModelField, itemStatus, itemsTypeEnum, logsModelField, logsType, myCreditModelField, mySqlDbTables,
  // eslint-disable-next-line comma-dangle
  mysqlCommonModel, saleItemModelField, saleModelField, saleTypeField, userModelField, userTypeRole
}
// eslint-disable-next-line no-multiple-empty-lines

