import { companyModelField, userModelField } from '@/api/field_model'
import { handleCurrentDate } from '@/assets/helpers'
import axiosIns, { baseURL } from '@/libs/axios'
import router from '@/router'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: false })
// const ls = new SecureLS({ isCompression: false })

// eslint-disable-next-line no-unused-vars
export const Ajax = async (url, { body = {} }) => {
  const URL = `${baseURL}${url}`
  let headers = {}

  const state = ls.get('vuex') ? JSON.parse(ls.get('vuex')) : {
    auth: { AppActiveUser: null, companyInfo: null },
  }
  // console.debug('state from axios', state)
  const { auth } = state
  if (auth && auth.companyInfo) {
    const expireDate = new Date(
      new Date(Date.now()).getFullYear(),
      new Date(Date.now()).getMonth() + 1,
      new Date(Date.now()).getDate()
                  + (auth.companyInfo[companyModelField.expireDayRemindDay]

                    || 0),
    ).toISOString().substr(0, 19).replace('T', ' ')

    // console.debug('auth', auth)
    let userCompanyData = { ...auth.companyInfo }
    if (auth.companyInfo.cloudineryConfig) {
      userCompanyData = null
    }
    const userData = { ...auth.AppActiveUser }

    if (userData !== null) {
      userData[userModelField.username] = ''
      userData[userModelField.password] = ''
    }
    if (userCompanyData != null) {
      userCompanyData[companyModelField.name] = ''
      userCompanyData[companyModelField.companyDomain] = ''
      userCompanyData[companyModelField.address] = ''
      userCompanyData[companyModelField.taxField] = ''
    }
    headers = {

      ...headers,
      userId: userData ? userData.id : null,
      user: JSON.stringify(userData),
      localdate: handleCurrentDate(),
      company: JSON.stringify(userCompanyData),
      expireDate,
    }
  }
  // console.debug('headers', headers)
  const response = await axiosIns.post(URL, body, headers ? {
    headers,
  } : null)
  // console.debug(response && response.data)
  if (response && response.data && response.data.code === '--1') {
    return router.push('/login')
  }
  return response ? response.data : null
}

export const RouteConst = {
  // company
  loginCompanyUrl: 'company/login',
  getCurrentCompanyUrl: 'company/getCurrentCompany',
  updateCompanyUrl: 'company/updateCompany',
  updateCompanySettingUrl: 'company/updateCompanySetting',
  changeCompanyPasswordUrl: 'company/changeCompanyPassword',
  resetCompanyPasswordUrl: 'company/resetCompanyPassword',
  submitPhoneNumberUrl: 'company/submitPhoneNumber',

  // users
  getAllUsersUrl: 'user/getAllUsers',
  loginWebUrl: 'user/loginWeb',
  addNewUserUrl: 'user/addNewUser',
  deleteUserUrl: 'user/deleteUser',
  updateUserUrl: 'user/updateUser',
  getPaginatedUsersUrl: 'user/getPaginatedUsers',

  // expense
  addExpenseUrl: 'user/expense/addExpense',
  getPaginatedExpenseUrl: 'user/expense/getPaginatedExpense',
  updateAllExpenseUrl: 'user/expense/updateAllExpense',
  deleteExpenseUrl: 'user/expense/deleteExpense',

  // credit
  addNewMyCreditToDBUrl: 'user/addNewMyCreditToDB',
  updateMyCreditUrl: 'user/updateMyCredit',
  getPaginatedMyCreditUrl: 'user/getPaginatedMyCredit',
  deleteMyCreditFromDBUrl: 'user/deleteMyCreditFromDB',
  updateSingleUrl: 'user/updateSingle',

  // global
  uploadImageUrl: 'global/uploadImage',
  getHomepageDataUrl: 'global/getHomepageData',
  getDownloadDataUrl: 'global/getDownloadData',
  deleteAllDataUrl: 'global/deleteAllData',

  // items
  addNewBranchToDBUrl: 'items/addNewBranchToDB',
  getPaginatedBranchesUrl: 'items/getPaginatedBranches',
  updateBranchUrl: 'items/updateBranch',
  deleteBranchFromDBUrl: 'items/deleteBranchFromDB',
  getAllBranchesUrl: 'items/getAllBranches',
  getBranchByIdUrl: 'items/getBranchById',

  addNewItemToCollectionUrl: 'items/addNewItemToCollection',
  updateCollectionUrl: 'items/updateCollection',
  deleteItemFromCollectionUrl: 'items/deleteItemFromCollection',
  getAllCollectionItemsUrl: 'items/getAllCollectionItems',
  getAllCollectionsUrl: 'items/getAllCollections',

  addNewSupplierToDBUrl: 'items/addNewSupplierToDB',
  updateSupplierUrl: 'items/updateSupplier',
  deleteSupplierFromDBUrl: 'items/deleteSupplierFromDB',
  getAllSuppliersUrl: 'items/getAllSuppliers',

  getPaginatedItemsUrl: 'items/getPaginatedItems',
  getPaginatedSearchItemsUrl: 'items/getPaginatedSearchItems',
  updateLowItemUrl: 'items/updateLowItem',
  addItemToDBUrl: 'items/addItemToDB',
  updateAllItemUrl: 'items/updateAllItem',
  stockInItemUrl: 'items/stockInItem',
  stockOutItemUrl: 'items/stockOutItem',
  transferItemFromBranchToBranchUrl:
      'items/transferItemFromBranchToBranch',
  deleteItemUrl: 'items/deleteItem',
  getItemByStackUrl: 'items/getItemByStack',
  getTopItemsUrl: 'items/getTopItems',
  getItemHistoryUrl: 'items/getItemHistory',

  getPaginatedItemHistoriesUrl: 'items/getPaginatedItemHistories',
  editItemHistoryUrl: 'items/editItemHistory',

  addNewCustomerToDBUrl: 'customer/addNewCustomerToDB',
  updateCustomerUrl: 'customer/updateCustomer',
  deleteCustomerFromDBUrl: 'customer/deleteCustomerFromDB',
  getPaginatedCustomersUrl: 'customer/getPaginatedCustomers',
  searchCustomerUrl: 'customer/searchCustomer',
  getCustomerWithThereCreditUrl:
    'customer/getCustomerWithThereCredit',

  addNewPurchaseToDBUrl: 'purchase/addNewPurchaseToDB',
  uploadOfflineSaleUrl: 'purchase/uploadOfflineSale',
  getPaginatedPurchaseUrl: 'purchase/getPaginatedPurchase',
  getSearchPaginatedPurchaseUrl:
    'purchase/getSearchPaginatedPurchase',
  updateCreditPurchaseUrl: 'purchase/updateCreditPurchase',
  returnSingleItemFromSaleUrl: 'purchase/returnSingleItemFromSale',
  returnAllSalesUrl: 'purchase/returnAllSales',
  getAllPurchaseByDateUrl: 'purchase/getAllPurchaseByDate',

  getPaginatedHistoryUrl: 'history/getPaginatedHistory',
  deleteHistoryUrl: 'history/deleteHistory',

}

export const isApiSuccess = req => req && (req.code === '0' || req.code === 0)
export const getApiData = req => req.data
export const getApiTotal = req => req.webData.sqlCount
export const getApiMsg = req => req.msg
export const getWebData = req => req.webData

export const LogicStateStatus = {
  loading: 'loading',
  loadingFirst: 'loadingFirst',
  success: 'success',
  fail: 'fail',
  none: 'none',
  loadNoData: 'loadNoData',
  deleteData: 'deleteData',
}

export const commonMutations = {
  CHANGE_LOGIC_STATUS: 'CHANGE_LOGIC_STATUS',
}
