/* eslint-disable class-methods-use-this */

const allowed = {
  // home
  sale: '1',

  // stock
  stock: '2',
  add2: '2.1',
  edit2: '2.2',
  delete2: '2.3',
  transfer2: '2.4',
  stockIn: '2.6',
  stockOut: '2.7',

  // store
  store: '3',
  add3: '3.1',
  edit3: '3.2',
  delete3: '3.3',
  transfer3: '3.4',

  myCustomers: '5.1',
  purchasePrice: '6',
  salesHistories: '1.2',

  // credit
  credit: '4',
}

export default class UserAllowed {
  isSaleAllowed(v) {
    return v.includes(allowed.sale)
  }

  isSalesHistoriesAllowed(v) {
    return v.includes(allowed.salesHistories)
  }

  isStockAllowed(v) {
    return v.includes(allowed.stock)
  }

  isAddStock(v) {
    return v.includes(allowed.add2)
  }

  isEditStock(v) {
    return v.includes(allowed.edit2)
  }

  isDeleteStock(v) {
    return v.includes(allowed.delete2)
  }

  isTransferStock(v) {
    return v.includes(allowed.transfer2)
  }

  isStoreAllowed(v) {
    return v.includes(allowed.store)
  }

  isAddStore(v) {
    return v.includes(allowed.add3)
  }

  isEditStore(v) {
    return v.includes(allowed.edit3)
  }

  isDeleteStore(v) {
    return v.includes(allowed.delete3)
  }

  isTransferStore(v) {
    return v.includes(allowed.transfer3)
  }

  isCreditAllowed(v) {
    return v.includes(allowed.credit)
  }

  isStockInAllowed(v) {
    return v.includes(allowed.stockIn)
  }

  isStockOutAllowed(v) {
    return v.includes(allowed.stockOut)
  }

  isPurchasePriceAllowed(v) {
    return v.includes(allowed.purchasePrice)
  }
}
