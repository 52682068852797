import { itemHistoriesModelField } from '@/api/field_model'

const stateM = {
  itemHistories: [],
}

const mutations = {
  GET(state, itemHistories) {
    state.itemHistories = itemHistories
  },
  EDIT(state, itemHistoryModel) {
    const itemHistoryCopy = [...state.itemHistories]

    const index = itemHistoryCopy.findIndex(element => itemHistoryModel.id === element.id)
    console.debug('EDIT', { index }, itemHistoryModel)
    if (index === -1) {
      return
    }

    itemHistoryCopy[index] = itemHistoryModel

    if (itemHistoryModel[itemHistoriesModelField.qty] === 0) {
      itemHistoryCopy.splice(index, 1)
    }

    state.itemHistories = itemHistoryCopy
  },
}

const actions = {

}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
}
