/* eslint-disable no-self-assign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import { Ajax, LogicStateStatus, RouteConst } from '@/libs/ajax'

const state = {
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
}

const actions = {
  getPaginatedItems(ctx, body) {
    return new Ajax(RouteConst.getPaginatedItemsUrl, body)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
