import UserAllowed from '@/auth/userAllowed'

const FULL = 'full'

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: FULL,
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: FULL,
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/LoginByPass',
    name: 'login-by-pass',
    component: () => import('@/views/LoginByPass.vue'),
    meta: {
      layout: FULL,
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/stock',
    name: 'stock',
    meta: {
      auth: new UserAllowed().isStockAllowed,
    },
    component: () => import('@/views/items/StockPage.vue'),
  },
  {
    path: '/store',
    name: 'store',
    meta: {
      auth: new UserAllowed().isStoreAllowed,
    },
    component: () => import('@/views/items/ShopPage.vue'),
  },
  // {
  //   path: '/add-item-shop',
  //   name: 'add-item-shop',
  //   meta: {
  //     auth: new UserAllowed().isAddStore,
  //   },
  //   component: () => import('@/views/store/AddItemToStore.vue'),
  // },
  // {
  //   path: '/import',
  //   name: 'import',
  //   meta: {
  //     auth: new UserAllowed().isAddStock,
  //   },
  //   component: () => import('@/views/excel/ImportExcel2.vue'),
  // },
  // {
  //   path: '/excel',
  //   name: 'excel',
  //   meta: {
  //     auth: new UserAllowed().isAddStock,
  //   },
  //   component: () => import('@/views/excel/excel.vue'),
  // },
  // {
  //   path: '/history',
  //   name: 'history',
  //   component: () => import('@/views/history/HistoryPage.vue'),
  // },
  {
    path: '/sale',
    name: 'sale',
    meta: {
      auth: new UserAllowed().isSaleAllowed,
    },
    component: () => import('@/views/sale/AddNewSale.vue'),
  },
  {
    path: '/sale-list',
    name: 'sale-list',
    component: () => import('@/views/saleList/SaleListPage.vue'),
  },
  // {
  //   path: '/warehouse-report',
  //   name: 'warehouse-report',
  //   meta: {
  //     isAdmin: true,
  //   },
  //   component: () => import('@/views/report/StockReportPage.vue'),
  // },
  // {
  //   path: '/shop-report',
  //   name: 'shop-report',
  //   meta: {
  //     isAdmin: true,
  //   },
  //   component: () => import('@/views/report/StoreReportPage.vue'),
  // },
  {
    path: '/business-report',
    name: 'business-report',
    meta: {
      isAdmin: true,
    },
    component: () => import('@/views/report/BusinessReportPage.vue'),
  },
  // {
  //   path: '/credit-report',
  //   name: 'credit-report',
  //   meta: {
  //     isAdmin: true,
  //   },
  //   component: () => import('@/views/report/CreditReportPage.vue'),
  // },
  // {
  //   path: '/low-in',
  //   name: 'low-in',
  //   component: () => import('@/views/home/LowIn.vue'),
  // },
  // {
  //   path: '/not-authorized',
  //   name: 'not-authorized',
  //   component: () => import('@/views/error/NotAuthorized.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  // },
  // {
  //   path: '/setting',
  //   name: 'setting',
  //   component: () => import('@/views/setting/AccountSetting.vue'),
  // },
]
