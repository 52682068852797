import { isDef } from '@vueuse/core'
import SecureLS from 'secure-ls'
import Vue from 'vue'
import NProgress from 'vue-nprogress'
import VueRouter from 'vue-router'
import pages from './routes/pages'

const nprogress = new NProgress()
nprogress.configure({
  // easing: 'ease', speed: 500
})

const ls = new SecureLS({ isCompression: false })
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        // pageTitle: 'Home',
        // breadcrumb: [
        //   {
        //     text: 'Home',
        //     active: true,
        //   },
        // ],
      },
    },
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  nprogress.done()
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  nprogress.start()
  // console.debug('to', to)
  // console.debug('from', from)
  // console.debug('info', JSON.parse(ls.get('vuex')))
  const state = ls.get('vuex') ? JSON.parse(ls.get('vuex')) : { auth: { AppActiveUser: null, companyInfo: null } }
  if (
    to.path === '/login'
  || to.path === '/error-404'
  || to.path === '/LoginByPass'
  || state.auth.AppActiveUser
  ) {
    if (!state.auth.companyInfo) {
      if (to.path !== '/login') {
        return router.push('/login')
      }
    }
    if (state.auth.AppActiveUser) {
      const userAllowed = state.auth.AppActiveUser ? state.auth.AppActiveUser.allowed : []
      const isAdmin = state.auth.AppActiveUser ? state.auth.AppActiveUser.isAdmin === true : false
      /// isAdmin
      if (isDef(to.meta.isAdmin)) {
        if (isAdmin) {
          return next()
        }
        return router.push({
          name: 'not-authorized',
        })
      }
      if (isDef(to.meta.auth)) {
        if (to.meta.auth(userAllowed) === true) {
          return next()
        }
        // console.debug(to.meta.auth, to.meta.auth(userAllowed))
        return router.push({
          name: 'not-authorized',
        })
      }
    }
    return next()
  }

  if (to.path !== '/LoginByPass') {
    return router.push('/LoginByPass')
  }
})

export default router
