/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { companyModelField, errorMessageKey, userTypeRole } from '@/api/field_model'
import router from '@/router'
import {
  Ajax,
  // eslint-disable-next-line comma-dangle
  RouteConst,
  getApiData,
  isApiSuccess,
} from '../../libs/ajax'

const state = {
  isUserLoggedIn: () => true,
  AppActiveUser: null,
  companyInfo: null,
  // let isAuthenticated = false

  // // get firebase current user
  // const firebaseCurrentUser = firebase.auth().currentUser

  // if (auth.isAuthenticated() || firebaseCurrentUser) isAuthenticated = true
  // else isAuthenticated = false

  // return localStorage.getItem('userInfo') && isAuthenticated
}

const mutations = {
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    // const userInfo = state.AppActiveUser

    // for (const property of Object.keys(payload)) {

    // if (payload[property] !== null) {
    //   // If some of user property is null - user default property defined in state.AppActiveUser
    //   state.AppActiveUser[property] = payload[property]

    //   // Update key in localStorage
    //   userInfo[property] = payload[property]
    // }
    const isAdmin = (payload.role || '') === userTypeRole.admin
    payload.isAdmin = isAdmin
    state.AppActiveUser = payload

    // }
    // Store data in localStorage
    // localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
  UPDATE_COMPANY_INFO(state, payload) {
    state.companyInfo = payload
  },
  UPDATE_COMPANY_INFO_ONE(state, payload) {
    state.companyInfo[payload.label] = payload.value
  },
  // eslint-disable-next-line no-unused-vars
  LOGOUT_USER(state, payload) {
    state.AppActiveUser = null
  },
  // eslint-disable-next-line no-unused-vars
  LOGOUT_COMPANY(state, payload) {
    state.companyInfo = null
  },
}

const actions = {
  logoutUser({ commit }, payload) {
    commit('LOGOUT_USER', payload)
    router.push('/LoginByPass')
  },
  logoutCompany({ commit }, payload) {
    commit('LOGOUT_COMPANY', payload)
    commit('LOGOUT_USER', payload)
    router.push('/login')
  },
  async loginByUser({ commit }, payload) {
    const res = await Ajax(RouteConst.loginWebUrl, {
      body: payload,
    })
    const data = getApiData(res)
    if (data.isPasswordCo === true) {
      // check if user not paid
      if (data.company[companyModelField.isNotPaid] === true) {
        throw Error('Subscription Expired, please complete your payment to continue!')
      }
      localStorage.setItem('loginAttemptTryTimes', 0)
      commit('UPDATE_USER_INFO', data.userInfo)
      commit('UPDATE_COMPANY_INFO', data.company)
      router.push('/')
    } else {
      throw Error('Incorrect password')
    }
  },
  async loginByCompany({ commit }, payload) {
    try {
      const res = await Ajax(RouteConst.loginCompanyUrl, {
        body: {
          companyModel: payload,
          [companyModelField.companyDomain]: payload[companyModelField.companyDomain],
          [companyModelField.companyPassword]: payload[companyModelField.companyPassword],
        },
      })
      const data = getApiData(res)
      if (isApiSuccess(res)) {
        // router.push(router.currentRoute.query.to || '/LoginByPass')
        commit('UPDATE_COMPANY_INFO', data)
        router.push('/LoginByPass')
      } else if (res.msg === errorMessageKey.noCompanyCode) {
        payload.notify('Company Not registered', 'No company has registered with the given company name please try again or contact us if your company has not been registered.')
      } else if (res.msg === errorMessageKey.incorrectPassword) {
        payload.notify('Error', 'Incorrect company password')
      } else {
        payload.notify('Error', res.msg)
      }
    } catch (err) {
      payload.notify('Error', err.message)
    }
  },
  async updateCompany({ commit }, payload) {
    const res = await Ajax(RouteConst.updateCompanyUrl, {
      body: {
        companyModel: payload,
        applyAll: payload.applyAll,
      },
    })
    if (isApiSuccess(res)) {
      commit('UPDATE_COMPANY_INFO_ONE', { label: Object.keys(payload)[0], value: Object.values(payload)[0] })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
