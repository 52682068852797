import {
  Ajax, commonMutations, getApiData, getApiTotal,
  isApiSuccess,
  LogicStateStatus,
  // eslint-disable-next-line comma-dangle
  RouteConst
} from '../../libs/ajax'

const stateM = {
  items: [],
  total: 0,
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
  UPDATE_ITEMS(state, payload) {
    state.items = payload
  },
  UPDATE_TOTAL(state, payload) {
    state.total = payload
  },
  CLEAR_STATE(state) {
    state.items = []
    state.total = 0
    state.currentPage = 1
  },
}

const actions = {
  async getPaginatedSearchItems({ commit }, payload) {
    const {
      page, query, filterBy, isShop, customBranchId, getAllBranch, alphabetically,
    } = payload
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const res = await Ajax(RouteConst.getPaginatedSearchItemsUrl, {
        body: {
          page,
          isShop: getAllBranch ? null : isShop,
          q: query,
          filterBy,
          customBranchId,
          alphabetically,
        },
      })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('UPDATE_ITEMS', data)
        if (getApiTotal(res) && getApiTotal(res) > 0) {
          commit('UPDATE_TOTAL', getApiTotal(res))
        }
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
}
