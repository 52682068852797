import {
  Ajax, getApiData,
  getApiMsg,
  isApiSuccess,
  // eslint-disable-next-line comma-dangle
  RouteConst
} from '../../libs/ajax'

const stateModule = {
  groups: [],
  marks: [],
  brands: [],
  measurements: [],
  suppliers: [],
  branches: [],
}

const mutations = {
  INIT_COLLECTIONS(state, payload) {
    state.groups = payload.groups
    state.marks = payload.marks
    state.brands = payload.brands
    state.measurements = payload.measurements
    state.suppliers = payload.suppliers
    state.branches = payload.branches
  },
}

const actions = {
  async getCollections({ commit }, payload) {
    const res = await Ajax(RouteConst.getAllCollectionsUrl, {
      body: payload,
    })
    const data = getApiData(res)
    if (isApiSuccess(res)) {
      commit('INIT_COLLECTIONS', data)
    } else {
      throw Error(getApiMsg(res))
    }
  },
}

export default {
  namespaced: true,
  state: stateModule,
  mutations,
  actions,
}
